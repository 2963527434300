.Container {
  margin: auto;
  padding: 30px;
  max-width: 600px;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CreditsDate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Name {
  font-weight: 800;
  font-size: 50px;
  text-align: center;
}

.Credits {
  display: flex;
  align-items: center;
  overflow: hidden;
  color: var(--color-dark);
  font-size: 20px;
  white-space: nowrap;
}

.CreditsImage {
  margin-right: 6px;
  height: 20px;
}

.Date {
  display: flex;
  align-items: center;
  margin-top: -8px;
  overflow: hidden;
  color: var(--color-dark);
  font-size: 20px;
  white-space: nowrap;
}

.DateImage {
  margin-right: 6px;
  height: 20px;
}

.Content {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.DescriptionArea {
  margin-top: 30px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 20px;
}

.Description {
  margin: 0;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
}

.WebsiteButton {
  margin-top: 32px;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
}

.Footer {
  margin-top: 30px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 20px;
}

.Technologies {
  display: grid;
  grid-template-columns: repeat(auto-fit, 64px);
  grid-gap: 32px;
}

.Links {
  display: grid;
  grid-template-columns: repeat(auto-fit, 50px);
  grid-gap: 32px;
}

.SocialMediaIcon {
  color: var(--color-dark);
  text-decoration: none;
}

.SocialMediaIcon:hover {
  transform: scale(1.15);
}

.Icon {
  margin-right: 2px;
}

.LoadingSpinner {
  font-size: 8px;
}

@media only screen and (min-width: 768px) {
  .Container {
    padding: 60px;
    max-width: 800px;
  }

  .Name {
    font-size: 72px;
  }

  .CreditsDate {
    flex-direction: row;
    align-items: normal;
    margin-top: 20px;
  }

  .Credits {
    margin-top: 0;
    margin-right: 10px;
  }

  .Date {
    margin-top: 0;
  }

  .LoadingSpinner {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) {
  .Container {
    max-width: 1200px;
  }

  .CreditsDate {
    margin-top: 0;
  }

  .Credits,
  .Date {
    margin-bottom: 0;
  }

  .Header {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  .Name {
    font-size: 50px;
    text-align: left;
  }

  .Content {
    flex-direction: row;
  }

  .DescriptionArea {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 0;
    margin-left: 30px;
  }

  .Description {
    margin-bottom: 0;
  }

  .WebsiteButton {
    margin-top: auto;
  }

  .LoadingSpinner {
    font-size: 12px;
  }
}
