.Navigation {
  display: flex;
  flex-direction: column;
}

.Title {
  cursor: pointer;
  box-shadow: 0 1px 0 var(--color-border);
  border: none;
  background-color: unset;
  padding: 16px;
  color: var(--color-dark);
  font-weight: 600;
  font-size: 18px;
  user-select: none;
  text-align: left;
}

.Body {
  margin-top: 32px;
}

@media (any-hover: hover) and (pointer: fine) {
  .Title:hover {
    box-shadow: 0 1px 0 var(--color-secondary);
  }
}

@media only screen and (min-width: 768px) {
  .Navigation {
    flex-direction: row;
    box-shadow: 0 1px 0 var(--color-border);
  }

  .Title {
    box-shadow: none;
  }
}
