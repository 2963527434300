.Container {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: -2px;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  padding: 20px;
  width: 100%;
}

.Link {
  width: 100%;
  color: var(--color-text);
  text-decoration: none;
}

.Link + .Link {
  padding-top: 6px;
}

.Link.active {
  color: var(--color-dark);
}

.ContainerToolbar {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 1000;
  box-sizing: border-box;
  margin-top: 65px;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-light);
  padding: 16px 72px;
  width: 100%;
}

.LinkToolbar {
  overflow: hidden;
  color: var(--color-text);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.LinkToolbar + .LinkToolbar {
  padding-top: 4px;
}

.LinkToolbar.active {
  color: var(--color-dark);
}

.Col {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--color-border);
  padding: 0 16px;
  width: 300px;
  overflow: hidden;
}

.Show {
  display: flex;
}

@media (any-hover: hover) and (pointer: fine) {
  .Link:hover {
    transition: 0.25s;
    color: var(--color-dark);
  }

  .LinkToolbar:hover {
    transition: 0.25s;
    color: var(--color-dark);
  }
}
