.Container {
  background-color: var(--color-accent);
  padding: 50px 0;
}

.Header {
  margin: auto;
  width: 80%;
  max-width: 1000px;
}

.Title {
  font-weight: 800;
  font-size: 38px;
}

.Table {
  margin: 8px auto 0 auto;
  border-collapse: collapse;
  width: 80%;
  max-width: 1000px;
}

.TableHeader {
  border: 1px solid var(--color-border);
  background-color: #1e4b5a;
  padding: 16px;
  color: var(--color-light);
  font-size: 18px;
  text-align: left;
}

.TermNameSelect {
  margin-left: 6px;
  border-radius: 14px;
  padding: 2px;
}

.TermNameSelectWrapper {
  display: flex;
  align-items: center;
  margin: 16px auto 0 auto;
  width: 80%;
  max-width: 1000px;
  color: var(--color-dark);
  font-size: 18px;
}

.Min768TableCell {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Min768None {
    display: none;
  }

  .Min768TableCell {
    display: table-cell;
  }

  .Table {
    margin: 16px auto 0 auto;
  }

  .TermNameSelect {
    margin-left: 8px;
    border: none;
  }

  .TermNameSelect:focus {
    box-shadow: 0 0 4px 0.8px var(--color-light);
  }

  .TermNameSelectWrapper {
    margin: 0;
    width: 100%;
    max-width: none;
    color: unset;
  }
}
