.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Info {
  font-weight: 300;
  font-size: 18px;
}

.Name {
  display: block;
  color: var(--color-dark);
}

.Proficiency {
  display: none;
  color: var(--color-dark);
}

/* .Container img:hover + .Info > .Name {
    display: none;
}

.Container img:hover + .Info > .Proficiency {
    display: block;
} */
