.Container {
  border: none;
  border-radius: 8px;
  background-color: var(--color-secondary);
  color: var(--color-light);
  text-decoration: none;
}

@media (any-hover: hover) and (pointer: fine) {
  .Container:hover {
    cursor: pointer;
    background-color: var(--color-hover);
  }
}
