.Container {
  background-color: var(--color-accent);
  padding: 50px 0;
}

.Header {
  margin: auto;
  width: 80%;
  max-width: 1000px;
}

.Title {
  font-weight: 800;
  font-size: 38px;
}
