@font-face {
  src:
    local('Raleway'),
    url('./fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype');
  font-family: Raleway;
}

@font-face {
  src:
    local('Quicksand'),
    url('./fonts/Quicksand/Quicksand-VariableFont_wght.ttf') format('truetype');
  font-family: Quicksand;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family:
    Quicksand,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: none;
}

:root {
  --color-primary: #14323c;
  --color-secondary: #393ae7;
  --color-accent: #f7f7f7;
  --color-accent-dark: #eeeeee;
  --color-text: #5a5a5a;
  --color-border: gainsboro;
  --color-hover: #5355d4;
  --color-success: green;
  --color-failure: red;
  --color-light: white;
  --color-dark: black;
}

#root {
  display: flex;
  flex-flow: column;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: var(--color-dark);
}

p {
  color: var(--color-text);
}

button {
  font-family: inherit;
}

select,
textarea,
input {
  display: block;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  background-clip: padding-box;
  background-color: var(--color-light);
  padding: 7px 14px;
  color: var(--color-dark);
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  font-family: inherit;
}

select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: 0 0 4px 0.8px rgb(57 58 231 / 35%);
  border-color: rgb(57 58 231 / 30%);
}
