.Container {
  padding: 50px 0;
}

.Header {
  margin: auto;
  width: 80%;
  max-width: 1000px;
}

.Title {
  font-weight: 800;
  font-size: 38px;
}

.MultiArea {
  margin: auto;
  margin-top: 16px;
  width: 80%;
  max-width: 1000px;
}

.Skills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
  grid-gap: 32px 32px;
  padding-top: 32px;
}
