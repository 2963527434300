.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px 0 8px;
  color: var(--color-dark);
  text-decoration: none;
}

.Icon {
  width: 45px;
  height: 45px;
}

.Name {
  padding-left: 6px;
  font-size: 23px;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

@media (any-hover: hover) and (pointer: fine) {
  .Container:hover > img {
    animation: spin 1s linear infinite;
  }
}
