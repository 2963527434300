.Container {
  display: flex;
  position: fixed;
  flex-direction: column;
  transform: translateX(-100%);
  z-index: 1050;
  transition: transform 0.3s ease-out;
  box-sizing: border-box;
  background-color: var(--color-light);
  width: 270px;
  height: 100%;
  overflow-y: auto;
}

.Show {
  transform: translateX(0);
}

.Header {
  border-bottom: 1px solid var(--color-border);
  padding: 16px;
}

.NavItems {
  width: 100%;
}

.Footer {
  box-sizing: border-box;
  margin-top: auto;
  border-top: 1px solid var(--color-border);
  padding: 0 16px;
  width: 100%;
}
