.Container {
  display: flex;
  flex-direction: column;
}

.Top {
  display: flex;
  position: relative;
}

.ArrowLeft,
.ArrowRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.2s ease;
  cursor: pointer;
  border: none;
  border-radius: 0;
  background-color: var(--color-dark);
  padding: 16px;
  color: var(--color-light);
  font-weight: bold;
  font-size: 16px;
  user-select: none;
}

.ArrowRight {
  right: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.Bottom {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 12px;
}

.Dot {
  display: inline-block;
  transition: 0.1s ease;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: #bbb;
  padding: 0;
  width: 14px;
  height: 14px;
}

@media (any-hover: hover) and (pointer: fine) {
  .ArrowRight {
    padding: 16px;
    width: fit-content;
    height: fit-content;
  }

  .Top:hover .ArrowLeft:hover,
  .Top:hover .ArrowRight:hover {
    opacity: 0.6;
  }

  .Top:hover .ArrowLeft,
  .Top:hover .ArrowRight {
    opacity: 0.3;
  }

  .Dot:hover {
    background-color: #8282da;
  }
}

.ArrowLeft:focus,
.ArrowRight:focus {
  opacity: 0.3;
}

@media only screen and (min-width: 768px) {
  .ArrowLeft,
  .ArrowRight {
    font-size: 42px;
  }
}

.ZoomImage {
  display: none;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  width: 700px;
  height: 700px;
}

.Image {
  border: 1px solid var(--color-border);
  border-radius: 8px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 1200px) {
  .Image {
    width: 700px;
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (any-hover: hover) and (pointer: fine) {
  .ZoomImage {
    display: block;
  }

  .Image {
    display: none;
  }
}
