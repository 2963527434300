.Container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  padding: 60px;
}

.HeroImageWrapper {
  z-index: 50;
  border: 5px solid var(--color-light);
  border-radius: 50%;
  background-color: var(--color-light);
  width: 300px;
  height: 300px;
}

.HeroImage {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.PrimaryText {
  z-index: 50;
  margin-top: 15px;
  color: var(--color-light);
  font-weight: bold;
  font-size: 52px;
  text-align: center;
  white-space: nowrap;
}

.StatusContainer {
  display: flex;
  position: absolute;
  left: calc(50% + 115px);
  justify-content: center;
  align-items: center;
  z-index: 50;
  cursor: default;
  box-shadow: 0px 0px 5px var(--color-dark);
  background-color: var(--color-primary);
  min-width: 30px;
  min-height: 30px;
  color: var(--color-light);
  font-size: 16px;
  line-height: 0;
}

.StatusMessage {
  color: var(--color-light);
  font-size: 12px;
}

.Min768Block,
.Min768Flex {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Min768None {
    display: none;
  }

  .Min768Block {
    display: block;
  }

  .Min768Flex {
    display: flex;
  }

  .PrimaryText {
    font-size: 64px;
  }
}
