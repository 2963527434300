.Data {
  border: 1px solid var(--color-border);
  padding: 16px;
  color: var(--color-dark);
  font-size: 16px;
}

.Min768TableCell {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Min768TableCell {
    display: table-cell;
  }

  .Data {
    font-size: 18px;
  }
}
