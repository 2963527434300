.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-border);
  padding: 0 20px;
  width: 100%;
  height: 65px;
}

.Min768Block {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Min768Block {
    display: block;
  }

  .Min768None {
    display: none;
  }
}
