.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  transition:
    border-color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  box-shadow: 0 0 8px var(--color-border);
  border: 1px solid var(--color-border);
  border-radius: 6px;
  background: var(--color-light);
  padding: 16px;
  width: 256px;
  text-decoration: none;
}

@media (any-hover: hover) and (pointer: fine) {
  .Container:hover {
    transform: scale(1.05);
    cursor: pointer;
    border: 1px solid var(--color-secondary);
  }
}

.Image {
  border: 1px solid var(--color-border);
  border-radius: 6px;
  width: 256px;
  height: 256px;
}

.Divider {
  margin: -4px;
  width: 100%;
}

.Name {
  color: var(--color-dark);
}

.Name,
.ShortDescription {
  margin: 0;
  text-align: center;
}
