.Container {
  margin: 0;
  width: 100%;
}

.Link {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 20px;
  width: 100%;
  color: var(--color-text);
  text-decoration: none;
}

.Link.active {
  background-color: var(--color-accent-dark);
  color: var(--color-dark);
}

.ContainerToolbar {
  margin: 0;
}

.LinkToolbar {
  padding: 12px 16px;
  height: 100%;
  color: var(--color-text);
  text-decoration: none;
}

.LinkToolbar.active {
  background-color: var(--color-accent-dark);
  color: var(--color-dark);
}

.Icon {
  margin-right: 8px;
}

@media (any-hover: hover) and (pointer: fine) {
  .Link:hover {
    transition: 0.25s;
    color: var(--color-dark);
  }

  .LinkToolbar:hover {
    transition: 0.25s;
    color: var(--color-dark);
  }
}
