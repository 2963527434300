.Container {
  margin: auto;
  width: 80%;
  max-width: 1000px;
}

.Title {
  font-weight: 800;
  font-size: 38px;
}

.Body {
  font-size: 16px;
  line-height: 28px;
  text-align: justify;
}

@media only screen and (min-width: 768px) {
  .Body {
    font-size: 18px;
  }
}
