.LoadingSpinner {
  animation: loading 1.1s infinite linear;
  border-top: 1.5em solid rgba(38, 132, 194, 0.2);
  border-right: 1.5em solid rgba(38, 132, 194, 0.2);
  border-bottom: 1.5em solid rgba(38, 132, 194, 0.2);
  border-left: 1.5em solid var(--color-secondary);
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
