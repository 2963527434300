.Container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  padding: 60px 30px;
}

.Header {
  z-index: 50;
  text-align: center;
}

.Title {
  color: var(--color-light);
  font-size: 72px;
}

.Min768Block {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Min768None {
    display: none;
  }

  .Min768Block {
    display: block;
  }
}
