.Container {
  margin: 0;
  width: 100%;
}

.CaretUp,
.CaretDown {
  margin-left: 4px;
}

.CaretUp {
  margin-top: 4px;
}

.Button,
.ButtonToolbar {
  display: flex;
  align-items: center;
  border: none;
  background-color: unset;
  color: var(--color-text);
  font-size: 18px;
  user-select: none;
}

.Button {
  box-sizing: border-box;
  padding: 16px 20px;
  width: 100%;
}

.Button.clicked {
  color: var(--color-dark);
}

.Button.active {
  background-color: var(--color-accent-dark);
  color: var(--color-dark);
}

.ContainerToolbar {
  margin: 0;
}

.ButtonToolbar {
  padding: 12px 16px;
  height: 100%;
}

.ButtonToolbar.clicked {
  color: var(--color-dark);
}

.ButtonToolbar.active {
  background-color: var(--color-accent-dark);
  color: var(--color-dark);
}

.Icon {
  margin-right: 8px;
}

@media (any-hover: hover) and (pointer: fine) {
  .Button:hover {
    transition: 0.25s;
    cursor: pointer;
    color: var(--color-dark);
  }

  .ButtonToolbar:hover {
    transition: 0.25s;
    cursor: pointer;
    color: var(--color-dark);
  }
}
