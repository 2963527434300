.Container {
  padding: 25px;
}

.Title {
  font-weight: 800;
  font-size: 42px;
  text-align: center;
}

.Description {
  font-size: 16px;
  text-align: center;
}

.HomeButton {
  display: block;
  margin: 36px auto 0;
  padding: 8px;
  width: 100%;
  max-width: 160px;
  font-size: 16px;
}

@media only screen and (min-width: 600px) {
  .Container {
    padding: 50px;
  }

  .Title {
    font-size: 64px;
  }

  .HomeButton {
    padding: 10px;
    max-width: 200px;
  }
}

@media only screen and (min-width: 768px) {
  .Title {
    font-size: 96px;
  }

  .Description {
    font-size: 20px;
  }

  .HomeButton {
    padding: 12px;
    font-size: 20px;
  }
}
