.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: auto;
  background-color: var(--color-primary);
  padding: 0 20px 16px;
  width: 100%;
}

.SocialMediaIcons {
  display: flex;
  align-items: center;
  margin: 16px 0;
}

.SocialMediaIcon + .SocialMediaIcon {
  margin-left: 22px;
}

.SocialMediaIcon {
  transform: scale(1.4);
  color: var(--color-light);
  text-decoration: none;
}

.CopyrightText {
  margin: 0;
  color: var(--color-light);
  font-size: 16px;
}

@media only screen and (min-width: 768px) {
  .Container {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    height: 65px;
  }

  .CopyrightText {
    font-size: 18px;
  }

  .SocialMediaIcon {
    transform: scale(1.5);
  }

  .SocialMediaIcon + .SocialMediaIcon {
    margin-left: 24px;
  }
}

@media (any-hover: hover) and (pointer: fine) {
  .SocialMediaIcon:hover {
    transform: scale(1.7);
  }
}
