.Container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 32px 32px;
  place-items: center;
  margin: auto;
  padding: 64px;
  max-width: 1300px;
}

.LoadingSpinner {
  font-size: 8px;
}

@media only screen and (min-width: 768px) {
  .LoadingSpinner {
    font-size: 12px;
  }
}
