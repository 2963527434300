.Container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: 1000;
  background-color: var(--color-dark);
  width: 100%;
  height: 100%;
}

.Show {
  display: block;
}
