.Container,
.SubmitMsgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 64px auto;
  border: 1px solid var(--color-border);
  border-radius: 12px;
  padding: 32px;
  width: 60%;
}

.Description {
  margin: 0 0 32px;
  max-width: 338px;
  font-size: 18px;
}

.Form > div {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 8px;
}

.Form > div:first-child {
  margin-top: 0;
}

.Form label {
  margin-bottom: 4px;
  padding: 0;
  width: fit-content;
  color: var(--color-dark);
  font-size: 16px;
}

.Form input,
textarea {
  appearance: none;
  width: 100%;
}

.Form textarea {
  height: 64px;
  resize: vertical;
}

.Form ul {
  margin: 0;
  padding: 0;
  color: var(--color-failure);
  font-size: 14px;
  list-style: none;
}

.SubmitButton {
  margin-top: 16px;
  padding: 10px 14px;
  font-size: 16px;
}

.SubmitMsg {
  flex-direction: column;
}

.SubmitMsgTitle {
  margin-top: 8px;
  color: var(--color-success);
  font-weight: 800;
  font-size: 50px;
  text-align: center;
}

.SubmitMsgDescription {
  margin-top: 8px;
  color: var(--color-success);
  font-size: 16px;
  text-align: center;
}

.EmailLink {
  border-radius: 4px;
  background-color: var(--color-accent-dark);
  padding: 2px 6px;
  color: var(--color-secondary);
  text-decoration: none;
}

.CheckMark {
  color: var(--color-success);
}

.LoadingSpinner {
  font-size: 8px;
}

@media (any-hover: hover) and (pointer: fine) {
  .EmailLink:hover {
    text-decoration: underline;
  }
}

@media only screen and (min-width: 768px) {
  .Container,
  .SubmitMsgContainer {
    flex-direction: row;
    padding: 64px;
    max-width: 800px;
  }

  .Description {
    margin: 0 48px 0 0;
    max-width: 290px;
  }

  .SubmitMsg {
    margin-left: 32px;
    white-space: nowrap;
  }

  .SubmitMsgTitle {
    margin-top: 0;
    font-size: 64px;
    text-align: left;
  }

  .SubmitMsgDescription {
    font-size: 20px;
    text-align: left;
  }

  .LoadingSpinner {
    font-size: 12px;
  }
}
