.Container {
  display: flex;
  gap: 16px;
  margin: auto;
  padding: 24px;
  width: 80%;
  max-width: 1000px;
}

.Info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Image {
  width: 72px;
  height: 72px;
}

.InfoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Title {
  margin: 0;
}

.Company,
.DateRange,
.Location {
  margin: 0;
  font-size: 16px;
}

.DateRange,
.Location {
  display: none;
}

@media only screen and (min-width: 768px) {
  .Company,
  .DateRange,
  .Location {
    display: block;
    font-size: 18px;
  }
}
